import React from 'react'
import { Routes, Route } from "react-router-dom";

import UserDashboard from '../../pages/user/DashBoard/Dashboard';
import UserLayout from '../../layout/user/UserLayout';
import Group from '../../pages/user/group/Group';
import Friends from '../../pages/user/friends/Friends';
import Posts from '../../pages/user/posts/posts';


const UserPrivateRoutes = () => {
  return (

   <>
   <UserLayout>
    <Routes>
        <Route path="/" element={<UserDashboard/>}/>
        <Route path="/groups" element={<Group/>}/>
        <Route path="/posts" element={<Posts/>}/>
        <Route path="/friends" element={<Friends/>}/>
        
        <Route path="*" element={<UserDashboard/>}/>

    </Routes>
    </UserLayout>
   </>
   
  )
}

export default UserPrivateRoutes