import axios from "axios";

const  API_URL = 'https://api.thesentinel.app/v1/api'

function updateAuthorizationHeader() {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["authorization"] = "Bearer " + token;
  }

  // Interceptor to handle 401 errors globally
axios.interceptors.response.use(
  response => {
    // If the response is successful, just return the response
    return response;
  },
  error => {
    // If the error response status is 401, handle logout
    if (error.response && error.response.status === 401) {
      handleLogout(); // Call the logout function if 401 error
    }
    return Promise.reject(error); // Otherwise, return the error to be handled
  }
);

// Function to handle logout, clear the token, and redirect
function handleLogout() {
  localStorage.removeItem("token"); // Remove token from localStorage
  window.location.href = "/"; // Redirect to the login page
}

  export async function userLogin (params) {
    return await axios.post(API_URL + "/user/login", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }

  export async function groupList(params) {
    updateAuthorizationHeader()
    return await axios.post(API_URL + "/group/get-listing", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }

    export async function deleteGroup(params) {
    updateAuthorizationHeader()
    return await axios.post(API_URL + "/group/delete-group", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }

  export async function addGroupResp(params) {
    updateAuthorizationHeader()
    return await axios.post(API_URL + "/group/response/add-update-new", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }

  export async function getGroupResp(params) {
    updateAuthorizationHeader()
    return await axios.post(API_URL + "/group/response/get-group-response", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }

  export async function getFriendsList(params) {
    updateAuthorizationHeader()
    return await axios.post(API_URL + "/user/friends/get-friend-listing", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }
  
  export async function deleteFriend(params) {
    updateAuthorizationHeader()
    return await axios.post(API_URL + "/user/friends/delete-friend", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }

  export async function getGrpReq(params) {
    updateAuthorizationHeader()
    return await axios.post(API_URL + "/request/get-listing", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }

  export async function addGrpReq(params) {
    updateAuthorizationHeader()
    return await axios.post(API_URL + "/request/add-and-update", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }

  export async function getPosts(params) {
    updateAuthorizationHeader()
    return await axios.post(API_URL + "/posts/get-listing",params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }
  
  export async function deletePost(id) {
    updateAuthorizationHeader()
    return await axios.post(API_URL + "/posts/delete-post",{id})
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }