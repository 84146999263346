import React from 'react';
import { Form, Input, Button, Checkbox, Typography, Space, Divider } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import './loginPage.css';
import { useNavigate } from 'react-router-dom';
import { accountLogin } from '../../../../services/apiCalls';
import { message  } from 'antd';

const { Title, Text } = Typography;

const LoginPage = () => {
  const navigate =useNavigate()
  const onFinish =async (values) => {
    const params = {
      email: values?.username,
      password: values?.password
    }
    try {
      const response =await accountLogin(params)
      if (response.status === 200) {
       const Token = response?.data?.data?.token
       localStorage.setItem("token",`${Token}`);
      window.location.href ="/"
      }else{
        message.error({
          content: 'Invalid credentials',
          style: {
            position: 'absolute',
            right: '0px',
            top: '0px',
          }
        })
      }
     
      
    } catch (error) {
      
    }
   
  };

  return (
    <div className="login-page" style={{alignItems:"center"}}>
      <div className="container" style={{width:"550px"}}>
        <div className="form-container" style={{textAlign:"center"}}>
          <Title level={2} className="title">
            Welcome Back
          </Title>
          <Text type="secondary" className="subtitle">
            Please log in to your account
          </Text>
          <Divider />
          <Form
            name="login_form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            className="form"
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'Please enter your username!' }]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please enter your password!' }]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Space className="options">
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>
                <span onClick={()=>navigate("/forgotPassword")}  className="forgot-password">
                  Forgot password?
                </span>
              </Space>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="submit-button"
                size="large"
              >
                Log in
              </Button>
            </Form.Item>
          </Form>

          <div className="register-link" onClick={()=>navigate("/signup")}>
            Don't have an account? 
            </div>
  
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
